<template lang="pug">
    .images-wrapper
        .image-section
            img(
                width="210"
                height="120"
                alt="Image"
                ref="img"
            )
            .info-block(v-if="isImageInfo && showDetails")
                .name {{image.name}}
                span {{imageSize}} MB
            .opacity-wrapper(:class="{ disable: disabled }")
            i.fa.fa-trash-o(
                @click="$emit('onDelete')"
                :class='{ hidden: disabled }'
            )
</template>

<script>
import { convertBytesToMB, fileToBase64 } from "@/utils";

export default {
  name: "BannerImageComponent",
  props: {
    image: {
      type: [File, String],
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
  },
  computed: {
    isImageInfo() {
      return this.image.name && this.image.size;
    },

    imageSize() {
      return convertBytesToMB(this.image.size);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let imgSrc = this.image;

      if (this.image instanceof File) {
        imgSrc = await fileToBase64(this.image);
      }

      this.$refs.img.src = imgSrc;
    },
  },
};
</script>

<style lang="stylus">
.images-wrapper
    padding 10px
    display inline-flex
    width 250px
    .image-section
        padding 10px
        display flex
        flex-direction column
        position relative
        width 100%
        border 1px solid #dfe1e6
        &:hover
            .opacity-wrapper
                display block
            .fa-trash-o
                position absolute
                display block
        img
            align-self center
            object-fit contain
        .info-block
            min-height 18px
            display flex
            .name
                width 150px
                display inline-block
                overflow hidden
                text-overflow ellipsis
                white-space nowrap
        .opacity-wrapper
            width 228px
            display none
            content ""
            top 0
            bottom 0
            right 0
            margin auto
            z-index 4
            background gray
            opacity 0.3
            position absolute
            &.disable
                cursor not-allowed
                display block
        .fa-trash-o
            display none
            font-size 18px
            color #2a4c70
            cursor pointer
            right 8px
            bottom 12px
            z-index 5
</style>
