import axios from "./axios";
import {
  root,
  events,
  AUTO_PRODUCTION_AUTO,
  AUTO_PRODUCTION_TACTICAL,
  AUTO_PRODUCTION_LEFT,
  AUTO_PRODUCTION_RIGHT,
  AUTO_PRODUCTION_CENTER,
  SPORT_TYPES,
  GAME_TYPES,
} from "@/constants";
import { buildResponseArrayGetInterceptor, buildSearchURL } from "@/utils";

const baseUrl = "/api/v3/events";
const NUMBER_OF_PLAYERS = [
  { id: "11x11", name: "11x11" },
  { id: "8x8", name: "8x8" },
  { id: "5x5", name: "5x5" },
];
const PERMISSIONS = [
  { id: "all", name: "All" },
  { id: "club", name: "Club" },
  { id: "admin", name: "Admin" },
];
const AUTO_PRODUCTION_OPTIONS = [
  { id: AUTO_PRODUCTION_AUTO, name: "Auto Camera", order: 1 },
  { id: AUTO_PRODUCTION_TACTICAL, name: "Tactical Mode", order: 2 },
  { id: AUTO_PRODUCTION_LEFT, name: "Left Side", order: 3 },
  { id: AUTO_PRODUCTION_RIGHT, name: "Right Side", order: 4 },
  { id: AUTO_PRODUCTION_CENTER, name: "Center", order: 5 },
];
const STREAM_TARGETS = [
  { id: "app", name: "App" },
  { id: "local", name: "Locally" },
];
const SDI_STREAM_TARGET = {
  id: "sdi",
  name: "SDI out",
};
const POST_UPLOAD_STREAM_TARGET = {
  id: "postUpload",
  name: "Post Upload",
};
const EVENT_STATUSES = [
  { id: "upcoming", name: "upcoming" },
  { id: "initializing", name: "initializing" },
  { id: "live", name: "live" },
  { id: "vod", name: "vod" },
];
const FILTER_OPTIONS = {
  permission: [
    { id: "all", title: "All" },
    { id: "club", title: "Club" },
    { id: "admin", title: "Admin" },
  ],
  sportType: [
    { id: "soccer", title: "Soccer" },
    { id: "basketball", title: "Basketball" },
    { id: "american_football", title: "American Football" },
    { id: "rugby", title: "Rugby" },
    { id: "hockey", title: "Ice Hockey" },
    { id: "fieldhockey", title: "Field Hockey" },
    { id: "rollerhockey", title: "Roller Hockey" },
    { id: "futsal", title: "Futsal" },
    { id: "handball", title: "Handball" },
    { id: "volleyball", title: "Volleyball" },
    { id: "wrestling", title: "Wrestling" },
    { id: "gymnastics", title: "Gymnastics" },
    { id: "beachvolleyball", title: "Beach Volleyball" },
    { id: "lacrosse", title: "Lacrosse" },
    { id: "baseball", title: "Baseball" },
    { id: "snooker", title: "Snooker" },
    { id: "softball", title: "Softball" },
    { id: "floorball", title: "Floorball" },
    { id: "cricket", title: "Cricket" },
    { id: "waterpolo", title: "Waterpolo" },
    { id: "netball", title: "Netball" },
    { id: "badminton", title: "Badminton (fixed frame)" },
    { id: "bowling", title: "Bowling (fixed frame)" },
    { id: "swimming", title: "Swimming (fixed frame)" },
    { id: "tennis", title: "Tennis (fixed frame)" },
    { id: "other", title: "Other" },
  ],
};
const SEARCH_OPTIONS = [
  { name: "Name", value: "name" },
  { name: "System Name", value: "system.name" },
  { name: "System ID", value: "system.id" },
  { name: "Event ID", value: "internalId" },
  {
    name: "Genius ID",
    value: "integrations.genius.id,integrations.genius.fixtureId",
  },
  { name: "Match ID", value: "integrations.genius.matchId" },
];
const DEFAULT_AUTO_PRODUCTION_OPTION = AUTO_PRODUCTION_OPTIONS[0];
const TAG_GENERAL_START = "general_start";
const TAG_GENERAL_END = "general_end";
const MANUAL = "manual";
const PUBLISHED = "published";
const SNS = "sns";
const AUTOHIGHLIGHT = "autohighlight";
const HIGHLIGHT_TOOLTIP = {
  [MANUAL]: "Custom Upload Highlight",
  [PUBLISHED]: "Admin Personal Tag Highlight",
  [SNS]: "Auto Production Highlight",
  [AUTOHIGHLIGHT]: "Tag Based Highlight",
  default: "Highlight",
};
const BULK_TYPES = [EVENT_STATUSES[0].id, EVENT_STATUSES[2].id, EVENT_STATUSES[3].id];

export default {
  get sportTypes() {
    return SPORT_TYPES.map((type) => ({ id: type.id, name: type.name }));
  },
  get gameTypes() {
    return GAME_TYPES;
  },
  get permissions() {
    return PERMISSIONS;
  },
  get streamTargets() {
    return STREAM_TARGETS;
  },
  get defaultAutoProductionOption() {
    return DEFAULT_AUTO_PRODUCTION_OPTION;
  },
  get defaultNumberOfPlayersOption() {
    return NUMBER_OF_PLAYERS[0];
  },
  get defaultPermission() {
    return PERMISSIONS[1];
  },
  get defaultHdStreamTarget() {
    return STREAM_TARGETS[0];
  },
  get defaultPanoStreamTarget() {
    return STREAM_TARGETS[0];
  },
  get SDIStreamTarget() {
    return SDI_STREAM_TARGET;
  },
  get tacticalAutoProduction() {
    return AUTO_PRODUCTION_TACTICAL;
  },
  get upcomingEventStatus() {
    return EVENT_STATUSES[0];
  },
  get initializingEventStatus() {
    return EVENT_STATUSES[1];
  },
  get liveEventStatus() {
    return EVENT_STATUSES[2];
  },
  get vodEventStatus() {
    return EVENT_STATUSES[3];
  },
  get startTagName() {
    return TAG_GENERAL_START;
  },
  get endTagName() {
    return TAG_GENERAL_END;
  },
  get filterOptions() {
    return FILTER_OPTIONS;
  },
  get searchFilters() {
    return SEARCH_OPTIONS;
  },
  get bulkTypes() {
    return BULK_TYPES;
  },
  get bulkStatuses() {
    return root.bulkStatuses;
  },
  get soccerGameType() {
    return SPORT_TYPES[0].id;
  },
  get basketballGameType() {
    return SPORT_TYPES[1].id;
  },
  get volleyBallGameType() {
    return SPORT_TYPES[9].id;
  },
  get hockeyGameType() {
    return SPORT_TYPES[4].id;
  },
  get rugbyGameType() {
    return SPORT_TYPES[3].id;
  },
  get handballGameType() {
    return SPORT_TYPES[8].id;
  },
  get futsalGameType() {
    return SPORT_TYPES[7].id;
  },
  get postUploadStreamTarget() {
    return POST_UPLOAD_STREAM_TARGET;
  },
  get bettingResolutions() {
    return root.bettingSettings;
  },

  getEvents(params) {
    return axios
      .get(`${baseUrl}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  getTags(id, params = { permission: "public" }) {
    return axios
      .get(`${baseUrl}/${id}/tags${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  getAllEvents(condition = {}, limit = 20, offset = 0) {
    let items = [];
    const origLimit = limit;

    const getEvents = (condition) => {
      return this.getEvents(condition).then((response) => {
        items = items.concat(response.items);

        if (response.pagination.next) {
          condition.offset += origLimit;

          return getEvents(condition);
        }

        return Promise.resolve();
      });
    };

    return getEvents(Object.assign({}, condition, { limit, offset })).then(() =>
      Promise.resolve(items),
    );
  },
  getEventById(id, condition) {
    const queryParams = new URLSearchParams(condition).toString();

    return axios.get(`${baseUrl}/${id}?${queryParams}`).then((item) => item.data);
  },
  getMainBeEvent(id) {
    return axios.get(`${baseUrl}/main-be/${id}`).then((item) => item.data);
  },
  getZixiStreamingUrl(id) {
    return axios.get(`${baseUrl}/${id}/zixi-streaming-url`).then((item) => item.data);
  },
  getStreamTargets(eventId) {
    return axios.get(`${baseUrl}/${eventId}/stream-targets`);
  },
  createEvent(data) {
    return axios.post(baseUrl, data);
  },
  addStreamTarget(eventId, data) {
    return axios.post(`${baseUrl}/${eventId}/stream-targets`, data);
  },
  updateEvent(eventId, data) {
    return axios.patch(`${baseUrl}/${eventId}`, data);
  },
  deleteHighlight(eventId, highlightId) {
    return axios.delete(`${baseUrl}/${eventId}/highlights/${highlightId}`);
  },
  addHighlight(eventId, data) {
    return axios.post(`${baseUrl}/${eventId}/highlights`, data);
  },
  deleteStreamTarget(eventId, targetIndex) {
    return axios.delete(`${baseUrl}/${eventId}/stream-targets/${targetIndex}`);
  },
  deleteNotLiveEvent(eventId) {
    return axios.delete(`${baseUrl}/${eventId}`);
  },
  deleteLiveEvent(event, eventStopTime, id) {
    return axios.patch(`${baseUrl}/${id}`, { endDateTime: eventStopTime });
  },
  getScoreboardLink(eventId) {
    return axios.get(`${baseUrl}/${eventId}/scoreboard`).then((response) => response.data);
  },
  getLptLink(eventId) {
    return axios.get(`${baseUrl}/${eventId}/lpt-link`).then((response) => response.data);
  },
  deleteEvent(event, id) {
    const start = new Date(event.startDateTime);
    const startTime = Date.UTC(
      start.getUTCFullYear(),
      start.getUTCMonth(),
      start.getUTCDate(),
      start.getUTCHours(),
      start.getUTCMinutes(),
      0,
      0,
    );
    const today = new Date();
    const now = Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      today.getUTCHours(),
      today.getUTCMinutes(),
      0,
      0,
    );
    const end = new Date(event.endDateTime);
    const endTime = Date.UTC(
      end.getUTCFullYear(),
      end.getUTCMonth(),
      end.getUTCDate(),
      end.getUTCHours(),
      end.getUTCMinutes(),
      0,
      0,
    );
    const eventStopTime = new Date(today.getTime() + 60 * 1000).toISOString();
    const { LIVE, UPCOMING, INITIALIZING, VOD } = events.events.statuses;

    if (
      (event.status === LIVE && now < endTime) ||
      (event.status === UPCOMING &&
        startTime < now + 3000 && // wait 3 sec for urls
        !Object.keys(event.urls).length)
    ) {
      return this.deleteLiveEvent(event, eventStopTime, id);
    } else if (event.status === LIVE && now > endTime) {
      return Promise.resolve("alreadyFinished");
    } else if ([INITIALIZING, UPCOMING, VOD].includes(event.status)) {
      return this.deleteNotLiveEvent(id);
    } else {
      return Promise.reject(new Error("Failed to delete. Unknown event status: " + event.status));
    }
  },
  bulkDeleteEvents(status, eventIds) {
    const query = eventIds.map((item) => `eventIds=${item}`).join("&");

    return axios.delete(`${baseUrl}/bulk/${status}?${query}`).then((response) => response.data);
  },
  bulkUpdateEvents(status, formData) {
    return axios.patch(`${baseUrl}/bulk/${status}`, formData).then((response) => {
      return {
        updatedEvents: response.data.updatedEvents,
        failedEvents: response.data.failedEvents,
      };
    });
  },
  createBulkEvents(formData) {
    return axios.post(`${baseUrl}/bulk`, formData).then((response) => response);
  },
  getAutoProductionOptions(sportType) {
    if (!sportType) {
      return [DEFAULT_AUTO_PRODUCTION_OPTION];
    }

    const optionsIds = SPORT_TYPES.find((type) => type.id === sportType).autoProductionOptions;

    return optionsIds
      ? AUTO_PRODUCTION_OPTIONS.filter((option) => optionsIds.includes(option.id))
      : [DEFAULT_AUTO_PRODUCTION_OPTION];
  },
  getMultiStreamAutoProductionOptions() {
    return [AUTO_PRODUCTION_OPTIONS[2], AUTO_PRODUCTION_OPTIONS[3], AUTO_PRODUCTION_OPTIONS[4]];
  },
  getNumberOfPlayersOptions() {
    return NUMBER_OF_PLAYERS;
  },
  buildBulkResultStateParams(successTitle, succeedData, failedData) {
    return {
      succeed: {
        title: this.bulkStatuses[successTitle],
        data: succeedData,
      },
      failed: {
        title: this.bulkStatuses.failed,
        data: failedData,
      },
      bulkType: this.bulkStatuses[successTitle].toLowerCase(),
    };
  },
  convertHLS2Mp4(eventId, options, params) {
    const queryParams = new URLSearchParams(params).toString();

    return axios.post(`${baseUrl}/${eventId}/convert-to-mp4?${queryParams}`, options);
  },
  getEventTags(eventId) {
    return axios.get(`${baseUrl}/${eventId}/tags?limit=100`).then((response) => response.data);
  },
  getHighlightImage(type) {
    return `assets/images/${type || "highlight-watch"}.png`;
  },
  getHighlightTooltip(type) {
    return HIGHLIGHT_TOOLTIP[type] || HIGHLIGHT_TOOLTIP.default;
  },
  getPlayerHighlights(id, params) {
    return axios
      .get(`/api/v3/player-highlights/event/${id}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  getEventsExportData(params) {
    return axios
      .get(`${baseUrl}/export-data${buildSearchURL(params)}`)
      .then((response) => response);
  },
  getMixerHighlights(id, params) {
    return axios
      .get(`/api/v3/mixer-highlights/event/${id}${buildSearchURL(params)}`)
      .then((response) => buildResponseArrayGetInterceptor(response, params));
  },
  removeMixerHighlight(highlightId) {
    return axios.delete(`/api/v3/mixer-highlights/${highlightId}`);
  },
};
